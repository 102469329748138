// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.republic-day-main {
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
}

.rp-imagescroll {
  display: flex;
  overflow-x: scroll;
  width: 100vw;
}

.rp-thumbnail {
  cursor: pointer;
  padding: 5px;
  margin: 5px;
  border: 1px solid #ccc;
}
.back-to-home{
  padding-left: 10px;
}

.back-to-home:hover {
  /* padding-left: 10px; */
  transform: scale(1.1);
}

.rp-thumbnail img {
  width: 100px; /* Adjust the width as needed */
  height: 10vh;
}

.rp-thumbnail.active {
  border-color: #007bff;
}

.rp-icons {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px; /* Adjust the font size as needed */
  cursor: pointer;
  color: #000; /* Icon color */
  margin: 0 10px; /* Adjust margin as needed */
}

.rp-icons:hover {
  color: #007bff; /* Change color on hover if desired */
}

@media only screen and (max-width: 768px) {
  .rp-imagescroll {
    /* width: 100%; */
    height: 100px;
  }

  .rp-thumbnail img {
    width: 100px; /* Adjust the width as needed */
    height: 100px;
  }

  .rp-imageDisplay img {
    width: 80vw;
    height: 40vh;
  }

  .rp-icons {
    margin: 0px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/Events/Events.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,kBAAkB;EAClB,YAAY;AACd;;AAEA;EACE,eAAe;EACf,YAAY;EACZ,WAAW;EACX,sBAAsB;AACxB;AACA;EACE,kBAAkB;AACpB;;AAEA;EACE,wBAAwB;EACxB,qBAAqB;AACvB;;AAEA;EACE,YAAY,EAAE,+BAA+B;EAC7C,YAAY;AACd;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,eAAe,EAAE,mCAAmC;EACpD,eAAe;EACf,WAAW,EAAE,eAAe;EAC5B,cAAc,EAAE,4BAA4B;AAC9C;;AAEA;EACE,cAAc,EAAE,qCAAqC;AACvD;;AAEA;EACE;IACE,iBAAiB;IACjB,aAAa;EACf;;EAEA;IACE,YAAY,EAAE,+BAA+B;IAC7C,aAAa;EACf;;EAEA;IACE,WAAW;IACX,YAAY;EACd;;EAEA;IACE,WAAW;EACb;AACF","sourcesContent":[".republic-day-main {\n  display: flex;\n  flex-direction: column;\n  margin-bottom: 30px;\n}\n\n.rp-imagescroll {\n  display: flex;\n  overflow-x: scroll;\n  width: 100vw;\n}\n\n.rp-thumbnail {\n  cursor: pointer;\n  padding: 5px;\n  margin: 5px;\n  border: 1px solid #ccc;\n}\n.back-to-home{\n  padding-left: 10px;\n}\n\n.back-to-home:hover {\n  /* padding-left: 10px; */\n  transform: scale(1.1);\n}\n\n.rp-thumbnail img {\n  width: 100px; /* Adjust the width as needed */\n  height: 10vh;\n}\n\n.rp-thumbnail.active {\n  border-color: #007bff;\n}\n\n.rp-icons {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  font-size: 24px; /* Adjust the font size as needed */\n  cursor: pointer;\n  color: #000; /* Icon color */\n  margin: 0 10px; /* Adjust margin as needed */\n}\n\n.rp-icons:hover {\n  color: #007bff; /* Change color on hover if desired */\n}\n\n@media only screen and (max-width: 768px) {\n  .rp-imagescroll {\n    /* width: 100%; */\n    height: 100px;\n  }\n\n  .rp-thumbnail img {\n    width: 100px; /* Adjust the width as needed */\n    height: 100px;\n  }\n\n  .rp-imageDisplay img {\n    width: 80vw;\n    height: 40vh;\n  }\n\n  .rp-icons {\n    margin: 0px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
