// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tableBoschairman {
    height: 90vh;
    width: 80vw;
    padding-top: 10px;
  }
  .searchBoschairman {
    width: 100%;
  }
  .headBoschairman {
    color: #370A68;
    font-weight: 600;
    font-size: 26px;
    width: 400px;
  }
  
  .searchTextBoschairman {
    width: 30%;
    padding-bottom: 0.5rem;
    margin-left: 50%;
  }
  
  @media screen and (max-width: 500px) {
    .searchBoschairman {
      width: 100%;
    }
    .tableBoschairman {
      height: 80vh;
      width: 100%;
    }
    .searchTextBoschairman {
      width: 50%;
      padding-bottom: 0.5rem;
      margin-left: 55%;
    }
  }
  `, "",{"version":3,"sources":["webpack://./src/components/MainPage/NavBar/Academics/Bos-chairman/Boschairman.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,WAAW;IACX,iBAAiB;EACnB;EACA;IACE,WAAW;EACb;EACA;IACE,cAAc;IACd,gBAAgB;IAChB,eAAe;IACf,YAAY;EACd;;EAEA;IACE,UAAU;IACV,sBAAsB;IACtB,gBAAgB;EAClB;;EAEA;IACE;MACE,WAAW;IACb;IACA;MACE,YAAY;MACZ,WAAW;IACb;IACA;MACE,UAAU;MACV,sBAAsB;MACtB,gBAAgB;IAClB;EACF","sourcesContent":[".tableBoschairman {\n    height: 90vh;\n    width: 80vw;\n    padding-top: 10px;\n  }\n  .searchBoschairman {\n    width: 100%;\n  }\n  .headBoschairman {\n    color: #370A68;\n    font-weight: 600;\n    font-size: 26px;\n    width: 400px;\n  }\n  \n  .searchTextBoschairman {\n    width: 30%;\n    padding-bottom: 0.5rem;\n    margin-left: 50%;\n  }\n  \n  @media screen and (max-width: 500px) {\n    .searchBoschairman {\n      width: 100%;\n    }\n    .tableBoschairman {\n      height: 80vh;\n      width: 100%;\n    }\n    .searchTextBoschairman {\n      width: 50%;\n      padding-bottom: 0.5rem;\n      margin-left: 55%;\n    }\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
