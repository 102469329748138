// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tableProgramOffered {
  height: 90vh;
  width: 80vw;
  padding-top: 10px;
  margin-bottom: 50px;
}
.searchProgramsOffered {
  width: 100%;
}
.headProgramsOffered {
  color: #370A68;
  font-weight: 600;
  font-size: 26px;
  width: 400px;
}

.searchTextProgramsOffered {
  width: 30%;
  padding-bottom: 0.5rem;
  margin-left: 50%;
}

@media screen and (max-width: 500px) {
  .searchProgramsOffered {
    width: 100%;
  }
  .tableProgramOffered {
    height: 80vh;
    width: 100%;
  }
  .searchTextProgramsOffered {
    width: 50%;
    padding-bottom: 0.5rem;
    margin-left: 55%;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/MainPage/NavBar/Academics/Programs Offered/Programs_Offered.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,WAAW;EACX,iBAAiB;EACjB,mBAAmB;AACrB;AACA;EACE,WAAW;AACb;AACA;EACE,cAAc;EACd,gBAAgB;EAChB,eAAe;EACf,YAAY;AACd;;AAEA;EACE,UAAU;EACV,sBAAsB;EACtB,gBAAgB;AAClB;;AAEA;EACE;IACE,WAAW;EACb;EACA;IACE,YAAY;IACZ,WAAW;EACb;EACA;IACE,UAAU;IACV,sBAAsB;IACtB,gBAAgB;EAClB;AACF","sourcesContent":[".tableProgramOffered {\n  height: 90vh;\n  width: 80vw;\n  padding-top: 10px;\n  margin-bottom: 50px;\n}\n.searchProgramsOffered {\n  width: 100%;\n}\n.headProgramsOffered {\n  color: #370A68;\n  font-weight: 600;\n  font-size: 26px;\n  width: 400px;\n}\n\n.searchTextProgramsOffered {\n  width: 30%;\n  padding-bottom: 0.5rem;\n  margin-left: 50%;\n}\n\n@media screen and (max-width: 500px) {\n  .searchProgramsOffered {\n    width: 100%;\n  }\n  .tableProgramOffered {\n    height: 80vh;\n    width: 100%;\n  }\n  .searchTextProgramsOffered {\n    width: 50%;\n    padding-bottom: 0.5rem;\n    margin-left: 55%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
