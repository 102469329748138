// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `


@media (max-width: 767px)  {

    .newsNUpdates{
        display: flex;
        flex-direction: column;
    }
    
}`, "",{"version":3,"sources":["webpack://./src/components/HomePage/Homepage.css"],"names":[],"mappings":";;;AAGA;;IAEI;QACI,aAAa;QACb,sBAAsB;IAC1B;;AAEJ","sourcesContent":["\n\n\n@media (max-width: 767px)  {\n\n    .newsNUpdates{\n        display: flex;\n        flex-direction: column;\n    }\n    \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
