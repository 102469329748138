// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.gallery-container {
  background-color: rgb(55, 10, 104);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.gallery-heading {
  color: white;
  font-size: 36px;
  margin: 20px 0;
  font-family: 'Inter', sans-serif;
}
.image-gallery {
  overflow: hidden;
  margin: 20px 0;
  width: 100%;
}

.image-scroll {
  display: inline-block;
  white-space: nowrap;
  animation: scroll 100s linear infinite; /* Adjust the duration based on your preference */
}

.image-scroll img {
  display: inline-block;
  margin-right: 20px;
  width: 300px; /* Set your desired width */
  height: 250px; /* Set your desired height */
  cursor: pointer;
}

/* Stop the scroll on hover */
.image-gallery:hover .image-scroll {
  animation-play-state: paused;
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-100%)); /* Adjust the value to eliminate any gap */
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/Gallery/Gallery.css"],"names":[],"mappings":"AAAA;EACE,kCAAkC;EAClC,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,aAAa;AACf;;AAEA;EACE,YAAY;EACZ,eAAe;EACf,cAAc;EACd,gCAAgC;AAClC;AACA;EACE,gBAAgB;EAChB,cAAc;EACd,WAAW;AACb;;AAEA;EACE,qBAAqB;EACrB,mBAAmB;EACnB,sCAAsC,EAAE,iDAAiD;AAC3F;;AAEA;EACE,qBAAqB;EACrB,kBAAkB;EAClB,YAAY,EAAE,2BAA2B;EACzC,aAAa,EAAE,4BAA4B;EAC3C,eAAe;AACjB;;AAEA,6BAA6B;AAC7B;EACE,4BAA4B;AAC9B;;AAEA;EACE;IACE,wBAAwB;EAC1B;EACA;IACE,kCAAkC,EAAE,0CAA0C;EAChF;AACF","sourcesContent":[".gallery-container {\n  background-color: rgb(55, 10, 104);\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  padding: 20px;\n}\n\n.gallery-heading {\n  color: white;\n  font-size: 36px;\n  margin: 20px 0;\n  font-family: 'Inter', sans-serif;\n}\n.image-gallery {\n  overflow: hidden;\n  margin: 20px 0;\n  width: 100%;\n}\n\n.image-scroll {\n  display: inline-block;\n  white-space: nowrap;\n  animation: scroll 100s linear infinite; /* Adjust the duration based on your preference */\n}\n\n.image-scroll img {\n  display: inline-block;\n  margin-right: 20px;\n  width: 300px; /* Set your desired width */\n  height: 250px; /* Set your desired height */\n  cursor: pointer;\n}\n\n/* Stop the scroll on hover */\n.image-gallery:hover .image-scroll {\n  animation-play-state: paused;\n}\n\n@keyframes scroll {\n  0% {\n    transform: translateX(0);\n  }\n  100% {\n    transform: translateX(calc(-100%)); /* Adjust the value to eliminate any gap */\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
