// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.required{
    color: red;
}
.cont{
    display: inline-block;
    margin: 2vw 25vw;
}

@media(max-width:900px) {
    .cont{
        margin: 0;
    }
    .w-50{
        font-size: 80%;
        min-width: 100%;
    }
    .form-control{
        max-width: 100%;
    }
    .container{
        min-width: 100%;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/GrievanceForm/Grievance.css"],"names":[],"mappings":"AAAA;IACI,UAAU;AACd;AACA;IACI,qBAAqB;IACrB,gBAAgB;AACpB;;AAEA;IACI;QACI,SAAS;IACb;IACA;QACI,cAAc;QACd,eAAe;IACnB;IACA;QACI,eAAe;IACnB;IACA;QACI,eAAe;IACnB;AACJ","sourcesContent":[".required{\n    color: red;\n}\n.cont{\n    display: inline-block;\n    margin: 2vw 25vw;\n}\n\n@media(max-width:900px) {\n    .cont{\n        margin: 0;\n    }\n    .w-50{\n        font-size: 80%;\n        min-width: 100%;\n    }\n    .form-control{\n        max-width: 100%;\n    }\n    .container{\n        min-width: 100%;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
