// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.allRightContentHolderHeading {
  color: #370A68;
  font-weight: 600;
  font-size: 26px;
  width: 400px;
  margin-top: 10px;

}
.allRightContentHolderContent h6 {
  /* text-decoration: underline; */
  font-style: italic;
  font-weight: 600;
  /* color: #2e0669; */
}
.allRightContentHolderContent p {
  font-size: 16px;
  /* letter-spacing: 0.3px; */
  line-height: 30px;
  text-align: justify;
}


@media (max-width: 767px) {
  .actualContainer {
    flex-direction: column;
    /* margin-left: 4px; */
  }

  .allRightContentHolder {
    width: 95%;
  }
  .allRightContentHolderContent{
    padding:0 5px 0 0;
  }

  .allRightContentHolderContent h3 {
    font-size: 18px;
  }

  .allRightContentHolderContent p {
    font-size: 14px;
    line-height: 24px;
  }
  .allRightContentHolderHeading {
    font-weight: 600;
    font-size: 26px;
    width: auto;
    margin-top: 10px;

  
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/MainPage/NavBar/Academics/Admissions/AdmissionsOP.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,gBAAgB;EAChB,eAAe;EACf,YAAY;EACZ,gBAAgB;;AAElB;AACA;EACE,gCAAgC;EAChC,kBAAkB;EAClB,gBAAgB;EAChB,oBAAoB;AACtB;AACA;EACE,eAAe;EACf,2BAA2B;EAC3B,iBAAiB;EACjB,mBAAmB;AACrB;;;AAGA;EACE;IACE,sBAAsB;IACtB,sBAAsB;EACxB;;EAEA;IACE,UAAU;EACZ;EACA;IACE,iBAAiB;EACnB;;EAEA;IACE,eAAe;EACjB;;EAEA;IACE,eAAe;IACf,iBAAiB;EACnB;EACA;IACE,gBAAgB;IAChB,eAAe;IACf,WAAW;IACX,gBAAgB;;;EAGlB;AACF","sourcesContent":[".allRightContentHolderHeading {\n  color: #370A68;\n  font-weight: 600;\n  font-size: 26px;\n  width: 400px;\n  margin-top: 10px;\n\n}\n.allRightContentHolderContent h6 {\n  /* text-decoration: underline; */\n  font-style: italic;\n  font-weight: 600;\n  /* color: #2e0669; */\n}\n.allRightContentHolderContent p {\n  font-size: 16px;\n  /* letter-spacing: 0.3px; */\n  line-height: 30px;\n  text-align: justify;\n}\n\n\n@media (max-width: 767px) {\n  .actualContainer {\n    flex-direction: column;\n    /* margin-left: 4px; */\n  }\n\n  .allRightContentHolder {\n    width: 95%;\n  }\n  .allRightContentHolderContent{\n    padding:0 5px 0 0;\n  }\n\n  .allRightContentHolderContent h3 {\n    font-size: 18px;\n  }\n\n  .allRightContentHolderContent p {\n    font-size: 14px;\n    line-height: 24px;\n  }\n  .allRightContentHolderHeading {\n    font-weight: 600;\n    font-size: 26px;\n    width: auto;\n    margin-top: 10px;\n\n  \n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
