// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.about-us-container {
    padding: 20px;
  }
  
  .about-us-card {
    margin: 20px 0;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  }
  
  .about-us-image {
    width: 100%;
    object-fit: cover;
    height: auto;
  }
  
  .about-us-title {
    color: #3f51b5;
    font-weight: bold;
    margin-bottom: 20px;
  }
  
  .about-us-section {
    padding: 10px;
    border-bottom: 1px solid #ddd;
  }
  
  .about-us-section:last-child {
    border-bottom: none;
  }
  
  .about-us-section-title {
    color: #333;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .about-us-section-text {
    color: #555;
    line-height: 1.6;
  }
  
  /* Responsive Styles */
  @media (max-width: 600px) {
    .about-us-container {
      padding: 10px;
    }
  
    .about-us-title {
      font-size: 24px;
    }
  
    .about-us-section-title {
      font-size: 18px;
    }
  
    .about-us-section-text {
      font-size: 14px;
    }
  }
  `, "",{"version":3,"sources":["webpack://./src/components/MainPage/NavBar/AntiRagging/AboutUS/AboutUs.css"],"names":[],"mappings":"AAAA;IACI,aAAa;EACf;;EAEA;IACE,cAAc;IACd,qCAAqC;EACvC;;EAEA;IACE,WAAW;IACX,iBAAiB;IACjB,YAAY;EACd;;EAEA;IACE,cAAc;IACd,iBAAiB;IACjB,mBAAmB;EACrB;;EAEA;IACE,aAAa;IACb,6BAA6B;EAC/B;;EAEA;IACE,mBAAmB;EACrB;;EAEA;IACE,WAAW;IACX,iBAAiB;IACjB,mBAAmB;EACrB;;EAEA;IACE,WAAW;IACX,gBAAgB;EAClB;;EAEA,sBAAsB;EACtB;IACE;MACE,aAAa;IACf;;IAEA;MACE,eAAe;IACjB;;IAEA;MACE,eAAe;IACjB;;IAEA;MACE,eAAe;IACjB;EACF","sourcesContent":[".about-us-container {\n    padding: 20px;\n  }\n  \n  .about-us-card {\n    margin: 20px 0;\n    box-shadow: 0 4px 8px rgba(0,0,0,0.1);\n  }\n  \n  .about-us-image {\n    width: 100%;\n    object-fit: cover;\n    height: auto;\n  }\n  \n  .about-us-title {\n    color: #3f51b5;\n    font-weight: bold;\n    margin-bottom: 20px;\n  }\n  \n  .about-us-section {\n    padding: 10px;\n    border-bottom: 1px solid #ddd;\n  }\n  \n  .about-us-section:last-child {\n    border-bottom: none;\n  }\n  \n  .about-us-section-title {\n    color: #333;\n    font-weight: bold;\n    margin-bottom: 10px;\n  }\n  \n  .about-us-section-text {\n    color: #555;\n    line-height: 1.6;\n  }\n  \n  /* Responsive Styles */\n  @media (max-width: 600px) {\n    .about-us-container {\n      padding: 10px;\n    }\n  \n    .about-us-title {\n      font-size: 24px;\n    }\n  \n    .about-us-section-title {\n      font-size: 18px;\n    }\n  \n    .about-us-section-text {\n      font-size: 14px;\n    }\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
