// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Search.css */
.search-container {
    position: relative;
    width: 100%;
    max-width: 400px;
    margin:0 auto;
  }
  
  .search-input {
    width: 100%;
    padding: 6px;
    border: 1px solid #ccc;
    border-radius: 4px;
    transition: width 0.3s ease;
  }
  
  .search-results {
    color: #2e0669;
    position: absolute;
    width: 100%;
    max-height: 200px;
    overflow-y: auto;
    background: white;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-top: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 999;
  }
  
  .search-item {
    padding: 6px;
    cursor: pointer;
    list-style: none;
  }
  
  .search-item:hover {
    background: #f0f0f0;
  }
  
  /* Responsive adjustments */
  @media (max-width: 768px) {
    .search-container {
      max-width: 300px;
    }
  
    .search-input {
      padding: 4px;
    }
  
    .search-item {
      padding: 6px;
    }
  }
  
  @media (max-width: 480px) {
    .search-container {
      max-width: 100%;
    }
  
    .search-input {
      padding: 3px;
    }
  
    .search-item {
      padding: 6px;
    }
  }
  `, "",{"version":3,"sources":["webpack://./src/components/MainPage/NavBar/Search/Search.css"],"names":[],"mappings":"AAAA,eAAe;AACf;IACI,kBAAkB;IAClB,WAAW;IACX,gBAAgB;IAChB,aAAa;EACf;;EAEA;IACE,WAAW;IACX,YAAY;IACZ,sBAAsB;IACtB,kBAAkB;IAClB,2BAA2B;EAC7B;;EAEA;IACE,cAAc;IACd,kBAAkB;IAClB,WAAW;IACX,iBAAiB;IACjB,gBAAgB;IAChB,iBAAiB;IACjB,sBAAsB;IACtB,kBAAkB;IAClB,eAAe;IACf,wCAAwC;IACxC,YAAY;EACd;;EAEA;IACE,YAAY;IACZ,eAAe;IACf,gBAAgB;EAClB;;EAEA;IACE,mBAAmB;EACrB;;EAEA,2BAA2B;EAC3B;IACE;MACE,gBAAgB;IAClB;;IAEA;MACE,YAAY;IACd;;IAEA;MACE,YAAY;IACd;EACF;;EAEA;IACE;MACE,eAAe;IACjB;;IAEA;MACE,YAAY;IACd;;IAEA;MACE,YAAY;IACd;EACF","sourcesContent":["/* Search.css */\n.search-container {\n    position: relative;\n    width: 100%;\n    max-width: 400px;\n    margin:0 auto;\n  }\n  \n  .search-input {\n    width: 100%;\n    padding: 6px;\n    border: 1px solid #ccc;\n    border-radius: 4px;\n    transition: width 0.3s ease;\n  }\n  \n  .search-results {\n    color: #2e0669;\n    position: absolute;\n    width: 100%;\n    max-height: 200px;\n    overflow-y: auto;\n    background: white;\n    border: 1px solid #ccc;\n    border-radius: 4px;\n    margin-top: 5px;\n    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);\n    z-index: 999;\n  }\n  \n  .search-item {\n    padding: 6px;\n    cursor: pointer;\n    list-style: none;\n  }\n  \n  .search-item:hover {\n    background: #f0f0f0;\n  }\n  \n  /* Responsive adjustments */\n  @media (max-width: 768px) {\n    .search-container {\n      max-width: 300px;\n    }\n  \n    .search-input {\n      padding: 4px;\n    }\n  \n    .search-item {\n      padding: 6px;\n    }\n  }\n  \n  @media (max-width: 480px) {\n    .search-container {\n      max-width: 100%;\n    }\n  \n    .search-input {\n      padding: 3px;\n    }\n  \n    .search-item {\n      padding: 6px;\n    }\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
