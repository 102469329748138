// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.typing-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-family: 'Inter', sans-serif
  }
  
  .typing-container h1 {
    color: #55b2e7;
    font-size: 2rem;
    font-weight: 750;
    display: inline-block;
    overflow: hidden;
    white-space: pre-wrap; /* Allow text to wrap onto multiple lines */
  }
  
  .caret {
    border-right: 0.15em solid #55b2e7;
    animation: blink-caret 0.75s step-end infinite;
  }
  
  @keyframes blink-caret {
    from, to {
      border-color: transparent;
    }
    50% {
      border-color: #55b2e7;
    }
  }
  
  @media (max-width: 768px) {
    .typing-container h1 {
      font-size: 2rem;
    }
  }
  
  @media (max-width: 480px) {
    .typing-container h1 {
      font-size: 1.5rem;
    }
  }
  `, "",{"version":3,"sources":["webpack://./src/components/HomePage/ImageGallery/TypingEffect.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,2BAA2B;IAC3B,mBAAmB;IACnB;EACF;;EAEA;IACE,cAAc;IACd,eAAe;IACf,gBAAgB;IAChB,qBAAqB;IACrB,gBAAgB;IAChB,qBAAqB,EAAE,2CAA2C;EACpE;;EAEA;IACE,kCAAkC;IAClC,8CAA8C;EAChD;;EAEA;IACE;MACE,yBAAyB;IAC3B;IACA;MACE,qBAAqB;IACvB;EACF;;EAEA;IACE;MACE,eAAe;IACjB;EACF;;EAEA;IACE;MACE,iBAAiB;IACnB;EACF","sourcesContent":[".typing-container {\n    display: flex;\n    justify-content: flex-start;\n    align-items: center;\n    font-family: 'Inter', sans-serif\n  }\n  \n  .typing-container h1 {\n    color: #55b2e7;\n    font-size: 2rem;\n    font-weight: 750;\n    display: inline-block;\n    overflow: hidden;\n    white-space: pre-wrap; /* Allow text to wrap onto multiple lines */\n  }\n  \n  .caret {\n    border-right: 0.15em solid #55b2e7;\n    animation: blink-caret 0.75s step-end infinite;\n  }\n  \n  @keyframes blink-caret {\n    from, to {\n      border-color: transparent;\n    }\n    50% {\n      border-color: #55b2e7;\n    }\n  }\n  \n  @media (max-width: 768px) {\n    .typing-container h1 {\n      font-size: 2rem;\n    }\n  }\n  \n  @media (max-width: 480px) {\n    .typing-container h1 {\n      font-size: 1.5rem;\n    }\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
