// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AffiliatedColleges {
  /* height: 90vh; */
  width: 80vw;
  padding-top: 10px;
}

.accordion-affiliated {
  margin-top: 20px;
}

.AffiliatedColleges-title {
  font-size: 26px;
  font-weight: 600;
  color: #370A68;
}

tr:nth-child(even +) {
  background-color: #f2f2f2;
}

.AffiliatedColleges-search {
  width: max-content;
  padding-bottom: 0.4rem;
  margin-left: 70%;
}

@media screen and (max-width: 500px) {
  .AffiliatedColleges {
    padding-top: 4rem;
    margin-left: 0px;
    width: 99%;
  }

  .table-container {
    width: 100%;
    padding: 0rem;
  }

  .AffiliatedColleges-search {
    width: 50%;
    padding-bottom: 0.5rem;
    margin-left: 50%;
  }

  .AffiliatedColleges-title {
    padding-left: 0rem;
    padding-top: 2rem;
    padding-bottom: 0.5rem;
    font-size: 26px;
    color: #690001;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/MainPage/NavBar/Academics/Affliated Colleges/AffiliatedCollegesOP.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,WAAW;EACX,iBAAiB;AACnB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,cAAc;AAChB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,kBAAkB;EAClB,sBAAsB;EACtB,gBAAgB;AAClB;;AAEA;EACE;IACE,iBAAiB;IACjB,gBAAgB;IAChB,UAAU;EACZ;;EAEA;IACE,WAAW;IACX,aAAa;EACf;;EAEA;IACE,UAAU;IACV,sBAAsB;IACtB,gBAAgB;EAClB;;EAEA;IACE,kBAAkB;IAClB,iBAAiB;IACjB,sBAAsB;IACtB,eAAe;IACf,cAAc;EAChB;AACF","sourcesContent":[".AffiliatedColleges {\n  /* height: 90vh; */\n  width: 80vw;\n  padding-top: 10px;\n}\n\n.accordion-affiliated {\n  margin-top: 20px;\n}\n\n.AffiliatedColleges-title {\n  font-size: 26px;\n  font-weight: 600;\n  color: #370A68;\n}\n\ntr:nth-child(even +) {\n  background-color: #f2f2f2;\n}\n\n.AffiliatedColleges-search {\n  width: max-content;\n  padding-bottom: 0.4rem;\n  margin-left: 70%;\n}\n\n@media screen and (max-width: 500px) {\n  .AffiliatedColleges {\n    padding-top: 4rem;\n    margin-left: 0px;\n    width: 99%;\n  }\n\n  .table-container {\n    width: 100%;\n    padding: 0rem;\n  }\n\n  .AffiliatedColleges-search {\n    width: 50%;\n    padding-bottom: 0.5rem;\n    margin-left: 50%;\n  }\n\n  .AffiliatedColleges-title {\n    padding-left: 0rem;\n    padding-top: 2rem;\n    padding-bottom: 0.5rem;\n    font-size: 26px;\n    color: #690001;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
