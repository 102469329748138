// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Styles for the YouTube component */

/* Background styling */
.youtube-background {
    background-color: #f0f4f7;
    /* background-image: linear-gradient(135deg, rgba(0, 123, 255, 0.5) 0%, rgba(0, 123, 255, 0.1) 100%); */
    padding: 40px 0;
  }
  
  .youtube-container {
    padding: 20px 0;
  }
  
  .youtube-video {
    width: 100%;
    overflow: hidden;
    position: relative;
    padding-bottom: 56.25%; /* 16:9 aspect ratio */
  }
  
  .youtube-video iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  
  /* Responsive design */
  @media (max-width: 600px) {
    .youtube-container {
      padding: 10px 0;
    }
  
    .youtube-video iframe {
      height: 215px;
    }
  }
  `, "",{"version":3,"sources":["webpack://./src/components/HomePage/Youtube/Youtube.css"],"names":[],"mappings":"AAAA,qCAAqC;;AAErC,uBAAuB;AACvB;IACI,yBAAyB;IACzB,uGAAuG;IACvG,eAAe;EACjB;;EAEA;IACE,eAAe;EACjB;;EAEA;IACE,WAAW;IACX,gBAAgB;IAChB,kBAAkB;IAClB,sBAAsB,EAAE,sBAAsB;EAChD;;EAEA;IACE,kBAAkB;IAClB,MAAM;IACN,OAAO;IACP,WAAW;IACX,YAAY;EACd;;EAEA,sBAAsB;EACtB;IACE;MACE,eAAe;IACjB;;IAEA;MACE,aAAa;IACf;EACF","sourcesContent":["/* Styles for the YouTube component */\n\n/* Background styling */\n.youtube-background {\n    background-color: #f0f4f7;\n    /* background-image: linear-gradient(135deg, rgba(0, 123, 255, 0.5) 0%, rgba(0, 123, 255, 0.1) 100%); */\n    padding: 40px 0;\n  }\n  \n  .youtube-container {\n    padding: 20px 0;\n  }\n  \n  .youtube-video {\n    width: 100%;\n    overflow: hidden;\n    position: relative;\n    padding-bottom: 56.25%; /* 16:9 aspect ratio */\n  }\n  \n  .youtube-video iframe {\n    position: absolute;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;\n  }\n  \n  /* Responsive design */\n  @media (max-width: 600px) {\n    .youtube-container {\n      padding: 10px 0;\n    }\n  \n    .youtube-video iframe {\n      height: 215px;\n    }\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
