// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Academicmain-Main {
  display: flex;
  margin-left: 30px;
}

.allLeftMenuAOP {
  margin-top: 30px;
  margin-right: 60px;
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.allLeftMenuAOP .menubutton {
  width: 240px;
  height: 60px;
  flex-shrink: 0;
  border-radius: 7px;
  color: black;
  padding: 10px;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #370a68;
  background: #fff;
  gap: 15px;
  font-weight: 600;
}

/* Hover state */
.allLeftMenuAOP .menubutton:hover {
  background-color: #370a68;
  color: #fff;
}

/* Selected state */
.allLeftMenuAOP .menubutton.active {
  background-color: #370a68;
  color: #fff;
  box-shadow: 0px 4px 22px 0px rgba(123, 124, 134, 0.36);
}

@media screen and (max-width: 600px) {
  .Academicmain-Main {
    display: flex;
    flex-direction: column;
    margin-left: 10px;
  }

  .allLeftMenuAOP .menubutton {
    width: -moz-fit-content;
    width: fit-content;
    height: auto;
    padding: 5px;
  }

  .allLeftMenuAOP{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/MainPage/NavBar/Academics/Academics.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,iBAAiB;AACnB;;AAEA;EACE,gBAAgB;EAChB,kBAAkB;EAClB,mBAAmB;EACnB,aAAa;EACb,sBAAsB;EACtB,SAAS;AACX;;AAEA;EACE,YAAY;EACZ,YAAY;EACZ,cAAc;EACd,kBAAkB;EAClB,YAAY;EACZ,aAAa;EACb,kBAAkB;EAClB,qBAAqB;EACrB,eAAe;EACf,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,yBAAyB;EACzB,gBAAgB;EAChB,SAAS;EACT,gBAAgB;AAClB;;AAEA,gBAAgB;AAChB;EACE,yBAAyB;EACzB,WAAW;AACb;;AAEA,mBAAmB;AACnB;EACE,yBAAyB;EACzB,WAAW;EACX,sDAAsD;AACxD;;AAEA;EACE;IACE,aAAa;IACb,sBAAsB;IACtB,iBAAiB;EACnB;;EAEA;IACE,uBAAkB;IAAlB,kBAAkB;IAClB,YAAY;IACZ,YAAY;EACd;;EAEA;IACE,aAAa;IACb,mBAAmB;IACnB,eAAe;EACjB;AACF","sourcesContent":[".Academicmain-Main {\n  display: flex;\n  margin-left: 30px;\n}\n\n.allLeftMenuAOP {\n  margin-top: 30px;\n  margin-right: 60px;\n  margin-bottom: 30px;\n  display: flex;\n  flex-direction: column;\n  gap: 10px;\n}\n\n.allLeftMenuAOP .menubutton {\n  width: 240px;\n  height: 60px;\n  flex-shrink: 0;\n  border-radius: 7px;\n  color: black;\n  padding: 10px;\n  text-align: center;\n  text-decoration: none;\n  cursor: pointer;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  border: 1px solid #370a68;\n  background: #fff;\n  gap: 15px;\n  font-weight: 600;\n}\n\n/* Hover state */\n.allLeftMenuAOP .menubutton:hover {\n  background-color: #370a68;\n  color: #fff;\n}\n\n/* Selected state */\n.allLeftMenuAOP .menubutton.active {\n  background-color: #370a68;\n  color: #fff;\n  box-shadow: 0px 4px 22px 0px rgba(123, 124, 134, 0.36);\n}\n\n@media screen and (max-width: 600px) {\n  .Academicmain-Main {\n    display: flex;\n    flex-direction: column;\n    margin-left: 10px;\n  }\n\n  .allLeftMenuAOP .menubutton {\n    width: fit-content;\n    height: auto;\n    padding: 5px;\n  }\n\n  .allLeftMenuAOP{\n    display: flex;\n    flex-direction: row;\n    flex-wrap: wrap;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
