// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Calender {
    /* height: 90vh; */
    /* width: 60vw; */
    padding-top: 10px;
    padding-bottom: 20px;
  }
  
  .accordion-affiliated {
    margin-top: 20px;
  }
  
  .calender-title {
    font-size: 26px;
    font-weight: 600;
    color: #370A68;
  }
  
  tr:nth-child(even +) {
    background-color: #f2f2f2;
  }
  
  .AffiliatedColleges-search {
    width: max-content;
    padding-bottom: 0.4rem;
    margin-left: 70%;
  }
  
  @media screen and (max-width: 500px) {
    .AffiliatedColleges {
      padding-top: 4rem;
      margin-left: 0px;
      width: 99%;
    }
  
    .table-container {
      width: 100%;
      padding: 0rem;
    }
  
    .AffiliatedColleges-search {
      width: 50%;
      padding-bottom: 0.5rem;
      margin-left: 50%;
    }
  
    .AffiliatedColleges-title {
      padding-left: 0rem;
      padding-top: 2rem;
      padding-bottom: 0.5rem;
      font-size: 26px;
      color: #690001;
    }
  }
  `, "",{"version":3,"sources":["webpack://./src/components/MainPage/NavBar/Academics/Calender/Calender.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,iBAAiB;IACjB,iBAAiB;IACjB,oBAAoB;EACtB;;EAEA;IACE,gBAAgB;EAClB;;EAEA;IACE,eAAe;IACf,gBAAgB;IAChB,cAAc;EAChB;;EAEA;IACE,yBAAyB;EAC3B;;EAEA;IACE,kBAAkB;IAClB,sBAAsB;IACtB,gBAAgB;EAClB;;EAEA;IACE;MACE,iBAAiB;MACjB,gBAAgB;MAChB,UAAU;IACZ;;IAEA;MACE,WAAW;MACX,aAAa;IACf;;IAEA;MACE,UAAU;MACV,sBAAsB;MACtB,gBAAgB;IAClB;;IAEA;MACE,kBAAkB;MAClB,iBAAiB;MACjB,sBAAsB;MACtB,eAAe;MACf,cAAc;IAChB;EACF","sourcesContent":[".Calender {\n    /* height: 90vh; */\n    /* width: 60vw; */\n    padding-top: 10px;\n    padding-bottom: 20px;\n  }\n  \n  .accordion-affiliated {\n    margin-top: 20px;\n  }\n  \n  .calender-title {\n    font-size: 26px;\n    font-weight: 600;\n    color: #370A68;\n  }\n  \n  tr:nth-child(even +) {\n    background-color: #f2f2f2;\n  }\n  \n  .AffiliatedColleges-search {\n    width: max-content;\n    padding-bottom: 0.4rem;\n    margin-left: 70%;\n  }\n  \n  @media screen and (max-width: 500px) {\n    .AffiliatedColleges {\n      padding-top: 4rem;\n      margin-left: 0px;\n      width: 99%;\n    }\n  \n    .table-container {\n      width: 100%;\n      padding: 0rem;\n    }\n  \n    .AffiliatedColleges-search {\n      width: 50%;\n      padding-bottom: 0.5rem;\n      margin-left: 50%;\n    }\n  \n    .AffiliatedColleges-title {\n      padding-left: 0rem;\n      padding-top: 2rem;\n      padding-bottom: 0.5rem;\n      font-size: 26px;\n      color: #690001;\n    }\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
