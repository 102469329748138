// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.allRightContentAOP{
  margin-top: 10px;

}

.clgnamediv {
  width: 100px;
  height: 10vh;
  text-decoration: none;
  color: #2e0669;
  margin-left: 100px;
  margin-top: 10px;
}

.clgdiv {
  margin-top: -20px;
}

.clgheaddiv {
  color: #370A68;
  font-weight: 500;
  font-size: 26px;
  /* width: 400px; */
}

.clgtextdivOP {
  background-color: whitesmoke;
  height: 40px;
  padding: 10px;
  font-weight: 400;
  font-size: medium;
}
`, "",{"version":3,"sources":["webpack://./src/components/MainPage/NavBar/Academics/Constituent Colleges/ConstituentCollegesOP.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;;AAElB;;AAEA;EACE,YAAY;EACZ,YAAY;EACZ,qBAAqB;EACrB,cAAc;EACd,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,cAAc;EACd,gBAAgB;EAChB,eAAe;EACf,kBAAkB;AACpB;;AAEA;EACE,4BAA4B;EAC5B,YAAY;EACZ,aAAa;EACb,gBAAgB;EAChB,iBAAiB;AACnB","sourcesContent":[".allRightContentAOP{\n  margin-top: 10px;\n\n}\n\n.clgnamediv {\n  width: 100px;\n  height: 10vh;\n  text-decoration: none;\n  color: #2e0669;\n  margin-left: 100px;\n  margin-top: 10px;\n}\n\n.clgdiv {\n  margin-top: -20px;\n}\n\n.clgheaddiv {\n  color: #370A68;\n  font-weight: 500;\n  font-size: 26px;\n  /* width: 400px; */\n}\n\n.clgtextdivOP {\n  background-color: whitesmoke;\n  height: 40px;\n  padding: 10px;\n  font-weight: 400;\n  font-size: medium;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
